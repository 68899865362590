<template>
    <div class="flex justify-content-center align-items-center" style="background-color: #0772b3; height: 80px">
        <a :href="linkLoja" target="_blank">
            <img src="layout/images/logoSebrae.svg" alt="sebrae-logo" style="width: 110px" />
        </a>
    </div>
    <section class="w-full m-auto mt-6 p-6 bg-gray-200" style="max-width: 900px">
        <div class="flex justify-content-between" style="height: auto; min-height: 120px; border-bottom: 2px solid #0000001a">
            <div>
                <h1 class="">{{ pedido?.produto?.produtoCheckouts[0]?.situacao != 2 ? 'Seu pedido está quase concluído!' : 'Parabéns!' }}</h1>
                <h2 class="mt-2">
                    {{ pedido?.produto?.produtoCheckouts[0]?.situacao != 2 ? 'Seu pedido está quase concluído!' : 'Seu pedido foi concluído com sucesso!' }}
                </h2>
            </div>
            <div>
                <a
                    :href="linkLoja"
                    target="_blank"
                    icon="pi pi-angle-left"
                    class="flex align-items-end p-2 pl-3 pr-3 mt-2 bg-blue-700 text-white"
                    style="border-radius: 4px; border: none"
                >
                    <i class="pi pi-angle-left mr-2" />Voltar a loja
                </a>
            </div>
        </div>
        <div class="flex flex-column md:flex-row mt-3 gap-3 md:align-items-center justify-content-between" style="width: 96%; min-height: 90px">
            <div class="mb-3 md:mb-0">
                <label class="mb-2" for="">Comprador</label>
                <p class="mt-2">{{ pedido?.nome }}</p>
            </div>
            <div class="mb-3 md:mb-0">
                <label class="mb-2" for="">Titular do pedido</label>
                <p class="mt-2">{{ $toCpf(pedido?.cpf) }}</p>
            </div>
            <div class="mb-3 md:mb-0">
                <label for="">Situação</label>
                <p class="mt-2">{{ pedido?.produto?.produtoCheckouts[0].situacaoDescricao }}</p>
            </div>
            <div class="mb-3 md:mb-0">
                <label class="mb-2" for="">Data de emissão</label>
                <p class="mt-2">{{ pedido?.produto?.produtoCheckouts[0].dataCadastroFormatada }}</p>
            </div>
        </div>
        <!-- detalhes do pedido -->
        <div class="mt-2" style="border-top: 2px solid #0000001a">
            <div class="mt-4">
                <h5 class="m-0 p-0">Detalhes do pedido</h5>
                <p class="text-600">Informações detalhadas sobre o pedido</p>
            </div>
            <div class="mt-3 w-full">
                <div class="mt-2" style="border-top: 2px solid #0000001a">
                    <div class="mt-4">
                        <h5 class="m-0 p-0">Detalhe(s) do(s) Produto(s)</h5>
                    </div>
                    <div class="grid col-12 p-0 mt-3">
                        <div class="fluid flex col-12 md:col-6" style="display: flex; flex-wrap: wrap">
                            <div class="flex flex-column mr-2" style="width: 48%">
                                <label class="mb-2" for="">Itens</label>
                                <img class="mr-3" style="width: 100%" src="layout/images/sebrae_pagamentos.jpg" alt="Imagem do produto" />
                            </div>
                            <div class="mt-3 md:50%">
                                <p class="mt-2 font-semibold">{{ pedido?.produto?.titulo }}</p>
                                <p class="mt-2">{{ pedido?.produto?.descricao }}</p>
                            </div>
                        </div>
                        <div class="col-12 md:col-2">
                            <label class="mb-2" for="">Data inicial</label>
                            <p class="mt-2">{{ $dateFormat(pedido?.produto?.dataInicial, 'DD/MM/YYYY') }}</p>
                        </div>
                        <div class="col-12 md:col-2">
                            <label class="mb-2" for="">Data final</label>
                            <p class="mt-2">{{ $dateFormat(pedido?.produto?.dataFinal, 'DD/MM/YYYY') }}</p>
                        </div>
                        <div class="col-12 md:col-2">
                            <label class="mb-2" for="">Valor</label>
                            <p class="mt-2">{{ pedido?.produto?.valorFormatado }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- resumo do pedido -->
        <div class="border-2 border-black-alpha-10 p-5 mt-6" style="border-radius: 16px">
            <h5 class="">Resumo do pedido</h5>
            <div>
                <div class="flex justify-content-between">
                    <label for="">Subtotal do(s) item(ns)</label>
                    <p>{{ pedido?.produto?.valorFormatado }}</p>
                </div>
                <div class="flex justify-content-between mt-1 mb-1">
                    <label for="">Desconto</label>
                    <p>-</p>
                </div>
                <div class="flex justify-content-between">
                    <label class="font-semibold" for="">Total</label>
                    <p class="font-semibold">{{ pedido?.produto?.valorFormatado }}</p>
                </div>
            </div>
            <div class="mt-3" style="border-top: 1px solid #0000001a">
                <div class="flex justify-content-between mt-3">
                    <p>A forma de pagamento escolhida foi:</p>
                    <p class="text-xl font-semibold">{{ this.formaPagamento() }}</p>
                </div>
            </div>
        </div>
        <!-- orientações adicionais -->
        <div class="card-orientacoes mt-6">
            <div class="flex align-items-center">
                <i class="pi pi-info-circle" style="font-size: 2rem; color: #486277"></i>
                <h5 class="p-0 m-0 ml-2">Orientações adicionais</h5>
            </div>
            <div>
                <ul style="padding-left: 20px">
                    <li class="text-600">
                        Evite contratempos. Confira a data, o horário e outras instruções de participação que serão enviadas para o seu e-mail. A sua presença é
                        muito importante.
                    </li>
                    <br />
                    <li class="text-600">
                        Informe-se sobre a nossa
                        <a href="/politicascheckoutpagamentos/Política de Privacidade de Clientes do Sebrae Pernambuco.pdf" target="_blank"
                            >Política de Privacidade</a
                        >
                        e
                        <a
                            href="/politicascheckoutpagamentos/POLÍTICA COMERCIAL - com diretriz de preços descontos e cortesias - 2023_27072023.pdf _manifesto_ (2).pdf"
                            target="_blank"
                            >Política de Comercialização</a
                        >
                        para saber mais sobre a segurança dos seus dados, regras para cancelamento, desistência da compra e disposições gerais.
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import service from './service';

export default {
    data() {
        return {
            pedido: null,
            linkLoja: process.env.VUE_APP_API_URL_LOJA_CHECKOUT,
        };
    },

    mounted() {
        this.checkoutPagamento();
    },

    methods: {
        formaPagamento() {
            if (this.pedido?.produto?.produtoCheckouts[0].tipoPagamento == 1) {
                if (this.pedido?.produto?.produtoCheckouts[0].qtdParcela > 1) {
                    return `${this.pedido?.produto?.produtoCheckouts[0].tipoPagamentoDescricao} ${this.pedido?.produto?.produtoCheckouts[0].qtdParcela} Parcelas`;
                } else {
                    return `${this.pedido?.produto?.produtoCheckouts[0].tipoPagamentoDescricao} ${this.pedido?.produto?.produtoCheckouts[0].qtdParcela} Parcela`;
                }
            }
            return this.pedido?.produto?.produtoCheckouts[0]?.tipoPagamentoDescricao || '';
        },
        checkoutPagamento() {
            this.$store.dispatch('addRequest');
            service
                .checkoutPagamento(this.$route.params.id)
                .then((response) => {
                    this.pedido = response.data;
                })
                .catch((error) => {
                    this.erro = error;
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar dados do pagamento', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>

<style scoped>
/* Estilos aqui */
h1,
h2,
h3,
p {
    margin: 0;
    padding: 0;
}
p {
    color: #424242;
}
label {
    color: #6c757d;
}
th {
    width: 100%;
}
th:first-child,
td:first-child {
    width: 70%; /* Define a largura da primeira coluna */
}
th:nth-child(2),
td:nth-child(2),
th:nth-child(3),
td:nth-child(3) {
    width: 15%; /* Define a largura das outras colunas */
    text-align: center;
    align-content: flex-start;
    padding-top: 8px;
    color: #424242;
}

.card-orientacoes {
    background-color: #d8e0e2;
    padding: 2rem;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
}
</style>
